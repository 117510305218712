import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit{
  @Input () columnsInput: TipoInput[] = [];
  @Input () formDatas: IFormData[] = [];
  @Output () outdata = new EventEmitter<any>();
  public step: number = 0;
  @Output () SalidaClickMenu =new EventEmitter<any>();


  ngOnInit(): void {
    console.log(this.formDatas[this.step]);
    if(Object.keys(this.formDatas[this.step].vars).length>0) {
            
    }
  }

 get FormDataActive() {
  if(!this.formDatas[this.step]) {
    this.formDatas[this.step] = new IFormData();
  }
  return this.formDatas[this.step];
}

EventoDinamic(event: any, itemForm: any) {

  const campo = itemForm.Campo;
  console.log(this.FormDataActive);
  this.FormDataActive.vars[campo] = event;
}
emitDate($event: any, campo: string, typeRangeDate: string) {
  if (!this.FormDataActive.vars[campo]) {
    this.FormDataActive.vars[campo] = {};
  }
  // this.FormDataActive.vars[campo][typeRangeDate] = $event.value;
  this.FormDataActive.vars[`${campo}_${typeRangeDate}`] = $event.value;
  console.log(this.FormDataActive.vars);
}
getRangeInputValue(value: any,key: string){
  if(!value) return "";
  return value.hasOwnProperty(key) ? value[key] : value; 
}
onSubmit($event: any){
  const valoresForm = {...this.FormDataActive.vars};
  const outputValue: any = {};
  const valoresDeValidar = this.columnsInput;
  valoresDeValidar.forEach( item => {
    if (valoresForm[item.Campo] === '') {
      valoresForm[item.Campo] = null;
    }
    if(item.TipoDato === 'Date' || item.TipoDato === 'Number') {
      if (valoresForm[`${item.Campo}_min`]  && valoresForm[`${item.Campo}_max`])  {
        let min = valoresForm[`${item.Campo}_min`];
        let max = valoresForm[`${item.Campo}_max`];
        valoresForm[item.Campo] = {min, max};
        outputValue[item.Campo] = {min, max};
      }
    
     
    }
    else{
      outputValue[item.Campo] = valoresForm[item.Campo]
    }
    console.log(item)
  });
  console.log(outputValue);
  this.outdata.emit(outputValue);
}
onClose() {
  this.SalidaClickMenu.emit({evento: 'cerrar'});
}
}

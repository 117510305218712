import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { SesionstorageService } from './sesionstorage.service';
import { Icb_QR } from '../models/tables';
import { SeguridadDatos } from './bscript.service';

interface IQrCompany {
  rif: string,
  idEmpConv: string;
  name: string
}

@Injectable({
  providedIn: 'root',
})
export class ApiClubService {
  private url: string = environment.URLApiLocal;

  constructor(public _sessionStorage: SesionstorageService, private security: SeguridadDatos) {}

  async generateQrCompany(data: IQrCompany): Promise<any> {
    try {
      let token = this._sessionStorage.GetSesionStorage('token');
      const headers = new HttpHeaders().set('tknlg', `Bearer ${token}`);
      let config = {
        headers: {
          tknlg: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      axios
        .post(
          `${this.url}/GeneratedQRCompany`,
          data,
          { headers: { 'X-API-Key': environment.apiKey } }
        )
        .then((res) => {
          
          console.log(res);
          return Promise.resolve({status: true, message: "Se ha generado correctamente el QR"})
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject({status: false, message: "Ha habido problemas al generar el QR"})
        });
    } catch (error) {
      console.error(error);
      return Promise.reject({status: false, message: "Ha habido problemas al generar el QR"});
      
    }
    
  }

  async ValidateCodiF(data: { CodigoFijo:string, idEmpConv:string, Action:string}) {

    return new Promise(async (resolve, reject) => {

      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateCod`, data , { headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async ValidateQREnterprise( idEmpConv: any, idQR?: any) {

    return new Promise(async (resolve, reject) => {
      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateAllyQR`, {idEmpConv,idQR} ,{ headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async validateOrden(dataFeatured: any) {

    return new Promise(async (resolve, reject) => {
      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateFeatureElement`, dataFeatured ,{ headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp.data);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async generateQR_Redirection(dataQR: Icb_QR | any): Promise<any>{
    try {
      const responseQR: any= await axios.post(`${this.url}/GetQRredirection`,dataQR);
      console.log(responseQR);
      if(responseQR.data.status){
      
        return Promise.resolve(responseQR.data.img)
      }else{
        return Promise.reject(" ")
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(" ");
    }
  }
  async getUsabilityGiftCards(): Promise<any>{
    try {
      const responseGiftCard: any= await axios.get(`${this.url}/gift-card/usability`);
      console.log(responseGiftCard);
      if(responseGiftCard.data.status){      
        return Promise.resolve(responseGiftCard.data)
      }else{
        return Promise.reject(responseGiftCard.data)
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(" ");
    }
  }
  async fetchUsabilityGiftCards(url: string, startBatch: number, limit: number, searchValue: string): Promise<any> {
    const token = this._sessionStorage.GetSesionStorage('token');
    const headers = {
      'tknlg': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    
    try {
      const response = await axios.get(`${url}/gift-card/usability?start=${startBatch}&length=${limit}&search=${searchValue}`, { headers });
      return response.data;
    } catch (error) {
      console.error('Error al obtener los datos de la API:', error);
      throw new Error('Error al obtener los datos de la API');
    }
  }
  async  fetchCouponReport(url: string = environment.URLApiLocal, limit: number, offset: number, searchValue: string,objectFilter?: any): Promise<any> {
    const token = this._sessionStorage.GetSesionStorage('token');
    console.log(objectFilter);
    const headers: any = {
      'tknlg': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    
    const {idEmpConv} = this._sessionStorage?.GetSesionStorage('login').Data;
    headers.filterObject = objectFilter ? JSON.stringify(objectFilter) : null;
    console.log(headers);
    try {
      const params: any = {
        limit,
        offset,
        search: searchValue,
        
      };
      console.log(idEmpConv);
      idEmpConv ? params.idEmpConv = idEmpConv : null;
      const response: any = await axios.get(`${url}/ReportCoupons`, { headers, params });
      if(response.status) return response.data;
      return [];
    } catch (error) {

      console.error('Error al obtener el reporte de cupones:', error);
      return [];
    }
  }
  
}

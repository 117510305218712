import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $, { param } from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { DataTableService } from 'src/app/services/datatable.service';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { ApiClubService } from 'src/app/services/api-club.service';
import Swal from 'sweetalert2';

interface IReport {
	status: boolean;
	data: IDataReport[]
}

interface IDataReport{
	Empresa:string;
	Ciudad:string;
	idAbonado:string;
	nombrPromocion:string;
	Canjeado:string;
	FechaGenerado:string;
	HoraGenerado:string;
	FechaCanjeado: string,
	HoraCanjeado: string,
	nombreAbonado:string;
}

@Component({
  selector: 'app-report-coupons',
  templateUrl: './reportCoupons.component.html',
  styleUrls: ['./reportCoupons.component.scss']
})
export class ReportCouponsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("datatable3", { static: true}) datatable3!: ElementRef;
	showMenu: boolean = false;
	showOptions: boolean = false;
	loading: boolean = true;
    currentTable: any[] = []
	private inicializedTable: boolean = false;
	public showFilter: boolean = false;
	public isfilteredTable: boolean = false;
	public errorFechaMax: boolean = false;
	public canShowInput: TipoInput[] = [];
	public columns: any[] = [];
	public estructuraInputs: TipoInput[] = [];
	public camposFilter: string[] = ["FechaGenerado","idEmpConv"];
	public columnsFilter: any[] = [];
	public datatablesParameters: any;

	constructor(private dbFull: dbFullService, private fb: FormBuilder, private helper: HelperService, private datatableService: DataTableService, private _apiHelpr: HelperService, private apiClub: ApiClubService){
		this.formG = this.fb.group({});
	}
	public dataForm: IFormData[] = [{defaultValues: {},error: {},vars: {}}];
	public data: any[] = [];
	public selectEmterprise: any[] = [] 
	selectProm: any[] = [];
	public dataAgrouped: any[]=[];
    
	public rowHead: any[] = ["EMPRESA","CIUDAD","AB", "NOMBRE AB", "PROMOCIÓN","CANJEADO","DÍA GENERADO","HORA GENERADO","FECHA CANJEADO","HORA CANJEADO"];

	public formG: FormGroup;

	public areExportingData: boolean = false;
	private createDynamicForm(formNames: TipoInput[]) {
		const controlsConfig: { [key: string]: any } = {};

		formNames.forEach(input => {
			if (input.TipoDato === 'Date') {
				controlsConfig[`${input.Campo}_min`] = [null];
				controlsConfig[`${input.Campo}_max`] = [null];
			} else {
				controlsConfig[input.Campo] = [null];
			}
		});

		this.formG = this.fb.group(controlsConfig);
	}



	public obsEnt!: Subscription;
	public table!: any;
	public dataFilter: any = {};
	public timerFilter!: NodeJS.Timeout; 
	public TipoDato: { [key: string]: { descripcion: string, render: (data: any) => string } } = {
		Area: {
		  descripcion: 'Área de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
		AutoIncrement: {
		  descripcion: 'Autoincremento',
		  render: (data: any) => Number.isInteger(data) ? data : 'N/A'
		},
		Avatar: {
		  descripcion: 'Imagen de avatar',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Boll: {
		  descripcion: 'Booleano',
		  render: (data: any) => data ? 'Sí' : 'No'
		},
		Date: {
		  descripcion: 'Fecha',
		  render: (data: any) => data ? new Date(data).toLocaleDateString('es-VE') : 'N/A'
		},
		TimeHour: {
			descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => { return data ?? "N/A"; }
				
		  },
		DateHour: {
	    	descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => {
				
				const date = new Date(data);
				console.log(data);
				const hours = date.getUTCHours().toString().padStart(2, '0');
				const minutes = date.getUTCMinutes().toString().padStart(2, '0');
				const seconds = date.getUTCSeconds().toString().padStart(2, '0');
				if(hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') return data ?? "N/A";
				const timeString = `${hours}:${minutes}:${seconds}`;
				return timeString;
			}
				
		  },
		Diccionario: {
		  descripcion: 'Diccionario',
		  render: (data: any) => data ? JSON.stringify(data) : 'N/A'
		},
		eAvatar: {
		  descripcion: 'Avatar extendido',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Email: {
		  descripcion: 'Correo electrónico',
		  render: (data: any) => data ? `<a href="mailto:${data}">${data}</a>` : 'N/A'
		},
		Map: {
		  descripcion: 'Mapa',
		  render: (data: any) => data ? data : 'N/A'
		},
		Money: {
		  descripcion: 'Dinero',
		  render: (data: any) => data ? `$${parseFloat(data).toFixed(2)}` : 'N/A'
		},
		Movil: {
		  descripcion: 'Número de móvil',
		  render: (data: any) => data ? data : 'N/A'
		},
		Name: {
		  descripcion: 'Nombre',
		  render: (data: any) => data ? data : 'N/A'
		},
		Number: {
		  descripcion: 'Número',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Numeric: {
		  descripcion: 'Numérico',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Password: {
		  descripcion: 'Contraseña',
		  render: (data: any) => data ? '******' : 'N/A'
		},
		String: {
		  descripcion: 'Cadena de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
		Switch: {
		  descripcion: 'Interruptor',
		  render: (data: any) => (typeof data === 'number' && data === 1) || data === true ? 'ACTIVO' : 'INACTIVO'
		}
	  };
	  
	  // Ejemplo de uso en DataTables

	  private searchSubject = new Subject<any>();

	ngOnInit(): void {

	}
	ngAfterViewInit(): void {
		this.initData();
    	const control =this.formG.get("selectEnt");
		if(control){
		this.obsEnt = control.valueChanges.subscribe(resp=>{
			this.selectProm = Object.keys(this.groupBy(this.dataAgrouped[resp],'nombrPromocion'));
			
      	})
    }
	
	}
    
	initTable() {
		($(this.datatable3.nativeElement) as any).DataTable({
			language: {
				searchPlaceholder: 'Buscar...',
				sSearch: '',
				
				info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
				lengthMenu: 'Mostrar _MENU_',
				paginate:{
					previous:'Anterior',
					next:'Siguiente'
				},
			},
			order: [[5, 'asc']],
			dom: 'Bfrtip',
    buttons: [
        'excelHtml5'
    ]
        });
		
	}
    toOpenMenu() {
        setTimeout(() => {
                document.body.addEventListener("click", this.toCloseMenu);
        }, 200)
        this.showMenu = true;
    }

    toCloseMenu = () => {
        document.body.removeEventListener("click", this.toCloseMenu)
        if(!this.showOptions){
            this.showMenu = false;
        }
        this.showOptions = false;
    }

    getStatistics(): Promise<{status: boolean}>{
        return new Promise(async(resolve,reject)=>{

            const data: IReport = <IReport> await this.dbFull.getStatistics();
			if(data && data.data.length>0){
                this.data=data.data;
                resolve({status: true});
            } 
            else{reject({status: false})}
        })
        
    }

    export() {  
        if (this.currentTable) {
        	const headers:string[] = []
        	const refHeaders:string[] = []
        	const form = this.formG;
			const minVlue  = (form.get("minD")?.value === null) ? "1969-12-31" : form.get("minD")?.value;
			const maxValue =  (form.get("maxD")?.value === null) ? "2999-12-31" : form.get("maxD")?.value;
			
		    const formMin  = new Date(minVlue);
			const formMax = new Date(maxValue);
			
			formMin.setMinutes(formMin.getMinutes() +formMin.getTimezoneOffset());
			
			// console.log(formMin);
			formMax.setUTCHours(23 );
			formMax.setUTCMinutes(59+ formMax.getTimezoneOffset());
			formMax.setUTCSeconds(59);
			const formEmpresa =  form.get("selectEnt")?.value;
			const formPromo =  form.get("selectProm")?.value;
			let sheets: any[][] = []
		

            //To set row headers
        	for (const header of this.rowHead) {
        		headers.push(header)
        	}
            for (const refHeader in this.data[0]) {
                refHeaders.push(refHeader)
            }
			// console.log(refHeaders.length);
			// if(refHeaders.length>=9){
			
				// const lastL =refHeaders.pop();
			
				// refHeaders.splice(3,0,lastL!);
			// }
			
        	sheets.push(headers)
            
        	for (let i = 0; i < this.currentTable.length; i++) {
        		let column: any[] = []
                let isvalidDateFilter = false;
				let isvalidEntFilter = false;
				
				// console.log(refHeaders);
        		for (const header of refHeaders) {   
                    // console.log("CANJEADO "+typeof(this.currentTable[i]['FechaCanjeado']))
					const empresas = this.currentTable[i]['Empresa'];
					const promociones = this.currentTable[i]['nombrPromocion']; 
					const date = new Date(this.currentTable[i]['FechaGenerado']);

					if (
						((formEmpresa === "" || formEmpresa === null) && (formPromo === "" || formPromo === null)) ||
						(formEmpresa ===  empresas  && (formPromo === null || formPromo==""))||
						(formEmpresa === empresas &&  formPromo === promociones ) 
					   ){ 
                         //console.log("la empresa "+form+"Es "+formEmpresa); 
						 isvalidEntFilter =true;
                    }
					
					if(
						(formMin === null && formMax === null) || 
						(formMin === null && date <= formMax) ||
						(formMin <= date && formMax === null) ||
						(formMin <= date && date <= formMax)
					)
					{
						// console.log("La fecha "+date+" Es valida")
						isvalidDateFilter= true;	
					}
        			if(header === 'FechaGenerado'){  
						column.push(this.helper.getDate(String( this.currentTable[i][header])).toUpperCase())
						
        			}
					else if (header === 'HoraGenerado') {  
						// console.log(this.currentTable[i][header])
						// console.log(this.helper.getTime(String(this.currentTable[i][header])).toUpperCase());
        				column.push(this.helper.getTime(String(this.currentTable[i][header])).toUpperCase())
						
        			}
					else if(header === 'FechaCanjeado' && this.currentTable[i]['FechaCanjeado'] ){
					
						column.push(String(this.helper.formatDatehyphen(this.currentTable[i][header]) ).toUpperCase())					
					}	
					else if(header === 'HoraCanjeado' && this.currentTable[i]['HoraCanjeado']){
						column.push(String(this.helper.formateTimeAMPM(this.currentTable[i][header]) ).toUpperCase())	
					}
					
					else{	
						if(this.currentTable[i][header] === null) column.push("")
						else column.push(String( this.currentTable[i][header]).toUpperCase())
        			}
        		}
				if(isvalidEntFilter  && isvalidDateFilter){
					sheets.push(column)
				}
        		
        	}
			// console.log(this.currentTable);
        	const workbook = XLSX.utils.book_new();
			const tabl_sort = this.sortMatriz(sheets);
			console.log(tabl_sort);
        	const worksheet = XLSX.utils.aoa_to_sheet(tabl_sort);
			const max_width = tabl_sort[0].reduce((w, r) => Math.max(w, r[0].length), 20);
			worksheet["!cols"] = [ { wch: max_width },
				{wch:10},  // Ancho de la columna 1
				{wch:30},  // Ancho de la columna 2
				{wch:45}   // Ancho de la columna 3
			];


        	XLSX.utils.book_append_sheet(workbook, worksheet);
        	var wopts = { bookType:"xlsx", bookSST:false, type:"array" } as const;
        	var wbout = XLSX.write(workbook, wopts);

        	const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"});
        	var reader = new FileReader();

        	reader.onload = (ev) => {
        		const base64URL = reader.result as string;

        		const linkElement = document.createElement("a");
        		linkElement.style.display = "none";
        		linkElement.href = base64URL;
        		linkElement.download = "reporte_cupones.xlsx";
        		linkElement.click();
        	}
        	reader.readAsDataURL(blob);
        }
    }
	//DESCARGAR DATOS DEL DATATABLES
	async getDataExcel() {
		const batchSize = 10000;
		let allData: any[] = [];
		let start = 0;
		let parameters = {...this.datatablesParameters};
		parameters.length = batchSize;
		while (true) {
			parameters.start = start;
			const response = await this.apiClub.fetchCouponReport(environment.URLApiLocal, batchSize, start, parameters.search.value, parameters.filterData);
			const responseData = response.data;
			allData = allData.concat(responseData.map((data: any) => {
				const row: any = {};
				const columns = this.estructuraInputs.filter((input: TipoInput) => input.ShowList== 1) ;
				columns.forEach((input: TipoInput) => {
					row[input.Campo] = data[input.Campo] ? this.TipoDato[input.TipoDato].render(data[input.Campo]) : '';
					// if (input.TipoDato === 'Date') {
					// 	row[input.Campo] = data[input.Campo] ? new Date(data[input.Campo]).toLocaleString() : '';
					// } else if (input.TipoDato === 'Number') {
					// 	row[input.Campo] = data[input.Campo] ? data[input.Campo].toString() : '';
					// } else {
					// 	row[input.Campo] = data[input.Campo] ? this.datatableService.render(input, data[input.Campo]) : '';
					// }
				});
				return row;
			}));

			if (responseData.length < batchSize) {
				break;
			}

			start += batchSize;
		}
		return allData;
	}
	onClickButtonExport(): void {
			Swal.fire({
				title: "¿Deseas descargar el documento?",
				showDenyButton: true,
				confirmButtonText: "Aceptar",
				denyButtonText: `Cancelar`
			  }).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) 
				 this.downloadExcel();
			  });
		}
	async  downloadExcel() {
		try {
			  this.areExportingData = true;
			  const allData = await this.getDataExcel();
			  console.log(allData,"allData");
		  
			  // Obtener los títulos de las columnas
			  const columnTitles: any = this.columns.reduce((acc: any, column: any) => {
				acc[column.data] = column.title;
				return acc;
			  }, {});
			  console.log("columnTitles",columnTitles);
			  
			  // Reemplazar claves con títulos
			  const excelData = allData.map(item => {
				const newItem: any = {};
				for (const key in item) {
				  if (item.hasOwnProperty(key)) {
					newItem[columnTitles[key]] = item[key];
				  }
				}
				return newItem;
			  });
			  console.log(excelData);
			  
			  const worksheet = XLSX.utils.json_to_sheet(excelData);
			  const workbook = XLSX.utils.book_new();
			  this.areExportingData = false;
			  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
			  XLSX.writeFile(workbook, 'reporte_cupones.xlsx');
		} catch (error) {
					
			this.areExportingData = false;
		  Swal.fire({
			
			icon: "error",
			title: "Error",
			text: "¡Ha ocurrido un error en la generación de su reporte por favor intente nuevamente!",
		  });
		  console.error('Error al descargar los datos:', error);
		}
	}
	async initData() {
		// const response: {status: boolean} = await this.getStatistics();
		// this.loading = false;
		// response.status 
		// ? this.currentTable = this.data
		// : this.currentTable = []        
		//Obetnemos el header de la tabla; 
		await this.getHeaderData();
		
		const columuns = this.canShowInput;
		const filterCampos = this.estructuraInputs.filter(input => this.camposFilter.includes(input.Campo));
		this.columnsFilter = filterCampos;
		console.log(this.columnsFilter);
		// {
		//  data: 'Empresa',
		//  title: 'Empresa'
		//   },
		console.log(this.canShowInput);
		
		this.columns = columuns.map(columnHeader => { 
			return {data: columnHeader.Campo, title: columnHeader.HeadListName, render: this.TipoDato[columnHeader.TipoDato].render}
		});
		console.log(this.columns);
		
		if (!this.inicializedTable) {
			this.inicializedTable = true;                
			this.table = ($(this.datatable3.nativeElement) as any).DataTable({
				language: {
					searchPlaceholder: 'Buscar...',
					sSearch: '',
					info: 'Mostrando _START_ - _END_ de _TOTAL_ registros',
					lengthMenu: 'Mostrar _MENU_',
					paginate: {
						previous: 'Anterior',
						next: 'Siguiente'
					}
				},
				processing: true, 
				serverSide: true,
				searchDelay: 1000,
				'ajax': (dataTablesParameters: any, callback: any) => { 
					if (this.dataFilter) {
						dataTablesParameters.filterData = this.dataFilter;
					}
					console.log(this.table);
					this.datatablesParameters = dataTablesParameters;
					
					// Usar throttle para limitar la frecuencia de las llamadas
					console.log( ($ as any).fn.dataTable.util);
					
					// const throttledRequest =setTimeout(() => {
						this.datatableService.getCouponReportCached(dataTablesParameters, this.estructuraInputs).then((resp: any) => { 
							console.log(dataTablesParameters);
							callback({ recordsTotal: resp.totalRecords, recordsFiltered: resp.filteredRecords, data: resp.data }); 
						});
					// }, 1000); // Límite de 1 segundo
					// console.log(throttledRequest);
					
					// throttledRequest();
				},
				columns: this.columns
			});
		}
		
		const value = this.table;
		console.log(value);
	}
	
	
	
	cambiarData(){
		
	
		const formG = this.formG;
		let min_forms = (formG.get("minD")?.value === null) ? "1969-12-31" : formG.get("minD")?.value;
		let max_forms =  (formG.get("maxD")?.value === null) ? "2999-12-31" : formG.get("maxD")?.value;
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
		
			let min: any =new Date(min_forms);
			min.setMinutes(min.getMinutes() + min.getTimezoneOffset());
			let max =new Date(max_forms);
			
			max.setUTCHours(23 );
			max.setUTCMinutes(59+ max.getTimezoneOffset());
			max.setUTCSeconds(59);
		
			
			console.log(data[6]);
			
			let   date = new Date(data[6]);
			date.setMinutes(date.getMinutes()+date.getTimezoneOffset());
		
			if (
				(min === null && max === null) || 
				(min === null && date <= max) ||
				(min <= date && max === null) ||
				(min <= date && date <= max)
			) {
				return true;
			}
			return false;
		});
		// console.log("drawing");
		
		//CONSECUENTEMENTE
		 this.changeEnterprise();
		
	
	}
	changeEnterprise(){
		// console.log("data");
		// this.table.draw();
		const formG = this.formG;
	
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
			
			let nombreEmp = formG.get("selectEnt")?.value;
			let nombrePromo = formG.get("selectProm")?.value;
			

			const dataEmpr = data[0];
			// console.log(dataEmpr);
			const dataPromo = data[4];
			 console.log(dataEmpr, dataPromo);
		 		if (
				 ((nombreEmp === "" || nombreEmp === null) && (nombrePromo === "" || nombrePromo === null)) ||

				 (nombreEmp === dataEmpr && (nombrePromo === null || nombrePromo==""))||
				 (nombreEmp === dataEmpr &&  nombrePromo === dataPromo ) 
				){return true;
				}
				else return false;
		});
		// console.log("drawing");
		
		this.table.draw();
		
	}
	showModalFilter(){
		
		this.showFilter=true;
		this.obtenerGrupo();
	}
	hideModalFilter(){
		this.showFilter=false; 
		
		if(this.isfilteredTable){
			
		if(this.timerFilter){
			clearTimeout(this.timerFilter);
		}	
		this.timerFilter = setTimeout(()=>{
			 
				this.cambiarData();
				
			},1000)	
		}
		
	}
	obtenerGrupo(){
       
		this.dataAgrouped = this.groupBy(this.currentTable,'Empresa');
       
		const groupList: any[] = Object.keys(this.dataAgrouped);
		this.selectEmterprise = groupList;
	
		}
	groupBy(array: any[], key: any) {
			return array.reduce((result, currentValue) => {
			if(typeof(currentValue)=="string"){
				(result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
			}
			else (result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
		  return result;
		}, {});
	}	
	confirmFilter(){
	const form = this.formG;

	if(form.valid && form.touched){
		if(new Date(form.get("maxD")?.value)>=new Date(form.get("minD")?.value)){
			this.errorFechaMax=false;
			this.isfilteredTable=true;

			this.hideModalFilter();
		}
		else if(form.get("minD")?.value && form.get("maxD")?.value==null){
			this.isfilteredTable=true;
			this.hideModalFilter();

		} 
		else{
           this.errorFechaMax=true;
		}
	}else if(form.untouched){ 
		
		this.hideModalFilter();}
     
	}
	stopFilter(){
		
		// ($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.isfilteredTable = false;
		this.dataForm = [];
		// this.formG.reset();
		// this.cambiarData();
		this.dataFilter = {};
		this.table.draw();	
	}
	sortMatriz(matriz: any[][])
	{
	const matrizOrdenada = matriz.slice(1); 
	matrizOrdenada.sort((a:any, b:any) => {
			if (a[0] === b[0] ) {
				if (a[3] === b[3] ) {
					return new Date(a[5]).getTime() - new Date(b[5]).getTime();
				}
				return a[3].localeCompare(b[3]);
			}
			return a[0].localeCompare(b[0]);
		});
	
		return [matriz[0], ...matrizOrdenada]
	}
	ngOnDestroy(): void {
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		// this.obsEnt.unsubscribe();
	}

	async getHeaderData() {
		try {
		  const Estructura: TipoInput[] = await this.dbFull.GetAllDataCampo('cb_Estructura', 'TableName', "Reporte_Club_FIbex");
	  
		  if (!Estructura || Estructura.length === 0) {
			throw new Error("No se encontró la estructura");
		  }
	  
		  // Ordenar la estructura
		  this.estructuraInputs = Estructura.sort((a, b) => a.OrdenList - b.OrdenList);
	  
		  // Filtrar entradas que se pueden mostrar
		  this.canShowInput = this.estructuraInputs.filter(input => input.ShowList === 1);
	  
		  // Puedes retornar la estructura ordenada y filtrada si es necesario
		  return this.estructuraInputs;
	  
		} catch (error) {
		  console.error('Error al obtener los datos de la estructura:', error);
		  throw error; // O manejar el error de una manera adecuada
		}
	  }
	  async getDataFilter($event: any){

		this.hideModalFilter(); 
		this.dataFilter= $event;
		if(Object.values(this.dataFilter).length>0){
			this.isfilteredTable=true;
			this.dataForm[0].vars = this.dataFilter;
		}
		console.log(this.dataForm);
		
		this.table.draw();
	  }
 }
